<template>
  <!--  fetchTrackingLog-->
  <div class="fragment">
    <template v-if="$store.getters.getTrackingLog.length > 0 && $store.getters.getTrackingLogLoading !== true">
      <div class="custom-row pt-4">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
             v-for="(item, index) in $store.getters.getTrackingLog"
             :key="index">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__head">
                <div class="case-cart__title-caption"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['userModule_paymentListings',])"></div>
                  {{ $t('deliveryStatistics_TrackingNumber.localization_value.value') }}
                </div>
                <div class="case-cart__title">

                  <span class="color-brown"
                        @click="openLogPopup(item.id)"
                  >
                    {{ item.tracking_number }}
                  </span>
                  <div class="case-cart__label-company">
                    <div class="case-cart__label-company-i">
                      <img v-if="item.delivery_service_id === GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.id" src="/img/company-icons-group/tab-fedex.svg" alt="ico">
                      <img v-if="item.delivery_service_id === GLOBAL_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.id" src="/img/company-icons-group/tab-tnt.svg" alt="ico">
                      <img v-if="item.delivery_service_id === GLOBAL_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.id" src="/img/company-icons-group/tab-dhl.svg" alt="ico">
                      <img v-if="item.delivery_service_id === GLOBAL_DELIVERY_SERVICES.USPS_DELIVERY_SERVICE.id" src="/img/company-icons-group/shipping-company/usps.svg" alt="ico">
                      <img v-if="item.delivery_service_id === GLOBAL_DELIVERY_SERVICES.LANDMARK_DELIVERY_SERVICE.id" src="/img/company-icons-group/radio-pictures-ico-usps.png" alt="ico">
                      <img v-if="item.delivery_service_id === GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.id" src="/img/company-icons-group/radio-pictures-ico-apc.svg" alt="ico">
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__body">
                <div class="case-cart__row custom-row">
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['userModule_paymentListings',])"></div>
                      {{ $t('deliveryStatistics_Accepted.localization_value.value') }}
                    </div>
                    <div class="case-cart__content">
                      {{ item.accepted_at | moment("DD MMM, YYYY") }}
                    </div>
                  </div>
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['userModule_paymentListings',])"></div>
                      {{ $t('deliveryStatistics_Delivered.localization_value.value') }}
                    </div>
                    <div class="case-cart__content">
                      <ValueHelper
                          :value="item.delivered_at"
                          :date="true"
                          :dateType="'DD MMM, YYYY'"
                      />
                    </div>
                  </div>
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['deliveryStatistics_Sender',])"></div>
                      {{ $t('deliveryStatistics_Sender.localization_value.value') }}
                    </div>
                    <div class="case-cart__content">
                      <ValueHelper
                          class="d-inline"
                          :value="item"
                          :deep="'order_data.user.country_name'"
                          :replaceValue="''"
                      /><template v-if="_.has(navTabs, GLOBAL_DELIVERY_SERVICES.USPS_DELIVERY_SERVICE.name) && !navTabs[GLOBAL_DELIVERY_SERVICES.USPS_DELIVERY_SERVICE.name].active">, <ValueHelper
                        class="d-inline"
                        :value="item"
                        :deep="'order_data.user.city'"
                        :replaceValue="''"
                      />
                    </template>
                    </div>
                  </div>
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['deliveryStatistics_Recepient',])"></div>
                      {{ $t('deliveryStatistics_Recepient.localization_value.value') }}
                    </div>
                    <div class="case-cart__content">
                      <ValueHelper
                          class="d-inline"
                          :value="item"
                          :deep="'order_data.recipient.country_name'"
                          :replaceValue="''"
                      />, <ValueHelper
                        class="d-inline"
                        :value="item"
                        :deep="'order_data.recipient.city'"
                        :replaceValue="''"
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
              v-if="$store.getters.getTrackingLogCommonList.next_page_url !== null && $store.getters.getTrackingLog.length > 0"
              @click.native="$emit('showMore')"
              :count="$store.getters.getTrackingLogCommonList.total - $store.getters.getTrackingLogForPage < $store.getters.getTrackingLogForPage ?
                  $store.getters.getTrackingLogCommonList.total - $store.getters.getTrackingLogForPage:
                  $store.getters.getTrackingLogForPage"
          />
        </div>
      </div>
      
    </template>

    <template v-if="$store.getters.getTrackingLogLoading === false && $store.getters.getTrackingLog.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>

    <!--      <div class="custom-col custom-col&#45;&#45;20 custom-col&#45;&#45;xs-100 custom-col&#45;&#45;md-50 custom-col&#45;&#45;xl-33">-->
    <!--        <div class="case-cart">-->
    <!--          <div class="case-cart__inner">-->
    <!--            <div class="case-cart__head">-->
    <!--              <div class="case-cart__title-caption">-->
    <!--                Tracking Number-->
    <!--              </div>-->
    <!--              <div class="case-cart__title">-->

    <!--                 <span class="color-brown"-->
    <!--                       @click="changeStatusOrderDetailsPopup(true)"-->
    <!--                 >-->
    <!--                  GE39489305UA3360-->
    <!--                </span>-->
    <!--                <div class="case-cart__label-company">-->
    <!--                  <div class="case-cart__label-company-i">-->
    <!--                    <img src="/img/company-icons-group/tab-tnt.svg" alt="ico">-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="case-cart__body">-->
    <!--              <div class="case-cart__row custom-row">-->
    <!--                <div class="case-cart__col custom-col custom-col&#45;&#45;50">-->
    <!--                  <div class="case-cart__label">-->
    <!--                    Accepted-->
    <!--                  </div>-->
    <!--                  <div class="case-cart__content">-->
    <!--                    15 Aug 2020-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--                <div class="case-cart__col custom-col custom-col&#45;&#45;50">-->
    <!--                  <div class="case-cart__label">-->
    <!--                    Delivered-->
    <!--                  </div>-->
    <!--                  <div class="case-cart__content">-->
    <!--                    18 Aug 2020-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--                <div class="case-cart__col custom-col custom-col&#45;&#45;50">-->
    <!--                  <div class="case-cart__label">-->
    <!--                    Sender-->
    <!--                  </div>-->
    <!--                  <div class="case-cart__content">-->
    <!--                    Ukraine, Dnipro-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--                <div class="case-cart__col custom-col custom-col&#45;&#45;50">-->
    <!--                  <div class="case-cart__label">-->
    <!--                    Recepient-->
    <!--                  </div>-->
    <!--                  <div class="case-cart__content">-->
    <!--                    France, Paris-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="custom-col custom-col&#45;&#45;20 custom-col&#45;&#45;xs-100 custom-col&#45;&#45;md-50 custom-col&#45;&#45;xl-33">-->
    <!--        <div class="case-cart">-->
    <!--          <div class="case-cart__inner">-->
    <!--            <div class="case-cart__head">-->
    <!--              <div class="case-cart__title-caption">-->
    <!--                Tracking Number-->
    <!--              </div>-->
    <!--              <div class="case-cart__title">-->
    <!--                <span class="color-brown"-->
    <!--                      @click="changeStatusOrderDetailsPopup(true)"-->
    <!--                >-->
    <!--                  GE39489305UA3360-->
    <!--                </span>-->
    <!--                <div class="case-cart__label-company">-->
    <!--                  <div class="case-cart__label-company-i">-->
    <!--                    <img src="/img/company-icons-group/tab-dhl.svg" alt="ico">-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="case-cart__body">-->
    <!--              <div class="case-cart__row custom-row">-->
    <!--                <div class="case-cart__col custom-col custom-col&#45;&#45;50">-->
    <!--                  <div class="case-cart__label">-->
    <!--                    Accepted-->
    <!--                  </div>-->
    <!--                  <div class="case-cart__content">-->
    <!--                    15 Aug 2020-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--                <div class="case-cart__col custom-col custom-col&#45;&#45;50">-->
    <!--                  <div class="case-cart__label">-->
    <!--                    Delivered-->
    <!--                  </div>-->
    <!--                  <div class="case-cart__content">-->
    <!--                    18 Aug 2020-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--                <div class="case-cart__col custom-col custom-col&#45;&#45;50">-->
    <!--                  <div class="case-cart__label">-->
    <!--                    Sender-->
    <!--                  </div>-->
    <!--                  <div class="case-cart__content">-->
    <!--                    Ukraine, Dnipro-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--                <div class="case-cart__col custom-col custom-col&#45;&#45;50">-->
    <!--                  <div class="case-cart__label">-->
    <!--                    Recepient-->
    <!--                  </div>-->
    <!--                  <div class="case-cart__content">-->
    <!--                    France, Paris-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->


    <StatusOrderDetailsPopup
        v-if="isModalStatusOrderDetailsPopup"
        :trackingLogItem="trackingLogItem"
        @close="isModalStatusOrderDetailsPopup = false"
    />
  </div>

<!--  <StatusOrderDetailsPopup-->
<!--      v-if="isModalStatusOrderDetailsPopup"-->
<!--      @close="changeStatusOrderDetailsPopup(false)"-->
<!--  />-->

<!--  </div>-->
</template>

<script>
import ShowMore from "@/components/coreComponents/Buttons/ShowMore/ShowMore";
import NoResult from "@/components/coreComponents/NoResult/NoResult";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import {GLOBAL_DELIVERY_SERVICES} from "@/staticData/staticVariables";
import StatusOrderDetailsPopup
  from "@/components/coreComponents/Popups/StatusOrderDetailsPopup/StatusOrderDetailsPopup";

export default {
  name: "DeliveryStatisticsTable",
  components: {
    StatusOrderDetailsPopup,
    ValueHelper,
    NoResult,
    ShowMore,
  },

  props: {
    countFilterParams: Number,
    navTabs: Object,
  },

  data() {
    return {
      trackingLogItem: {},
      isModalStatusOrderDetailsPopup: false,
      GLOBAL_DELIVERY_SERVICES: GLOBAL_DELIVERY_SERVICES,
    }
  },

  methods: {
    // changeStatusOrderDetailsPopup(val) {
    //   this.isModalStatusOrderDetailsPopup = val
    // }

    openLogPopup(id) {
      this.$store.dispatch('getTrackingLog', id).then(response => {
        this.trackingLogItem = this.getRespData(response)
        this.isModalStatusOrderDetailsPopup = true
        // console.log(data);
      })

    },
  }
}
</script>

<style scoped>

</style>