<template>
  <div>
    <DeliveryStatisticsHead
        :showFilter="showFilter"
        :navTabs="navTabs"
        :filterGetParams="filterGetParams"
        :countFilterParams="countFilterParams"
        :loadingDeliveryStatistics="loadingDeliveryStatistics"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeFilter="changeFilter"
        @changeTab="(data) => $emit('changeTab', data)"
        @reloadTable="$emit('reloadTable')"
        @changePeriod="(val) => $emit('changePeriod', val)"
    />

    <div class="table-filter-wrap">

      <DeliveryStatisticsFilter
          :navTabs="navTabs"
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          :countFilterParams="countFilterParams"
          :selectWarehouseCountryOptions="selectWarehouseCountryOptions"
          :selectWarehouseOptions="selectWarehouseOptions"
          @close="closeFilter"
          @changeFilter="changeFilter"
          @resetFilter="$emit('resetFilter')"
      />

      <DeliveryStatisticsTable
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
      />
    </div>
  </div>
</template>

<script>


  import DeliveryStatisticsHead from "./DeliveryStatisticsHead/DeliveryStatisticsHead";
  import DeliveryStatisticsTable from "./DeliveryStatisticsTable/DeliveryStatisticsTable";
  import DeliveryStatisticsFilter from "./DeliveryStatisticsFilter/DeliveryStatisticsFilter";

  export default {
    name: "DeliveryStatisticsTableUser",

    components: {
      DeliveryStatisticsHead,
      DeliveryStatisticsTable,
      DeliveryStatisticsFilter,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
      navTabs: Object,
      loadingDeliveryStatistics: Boolean,
      selectWarehouseCountryOptions: Array,
      selectWarehouseOptions: Array,
    },

    data(){
      return{
        showFilter: false,
      }
    },

    watch: {
      typeShops(newVal) {
        this.typeShops = newVal
      },
      filterGetParams(newVal) {
        this.filterGetParams = newVal
      },
      countFilterParams(newVal) {
        this.countFilterParams = newVal
      },
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeShopType(index) {
        this.$emit('changeShopType', index)
      },


      changeFilter(data) {
        this.$emit('changeFilter', data)
      },

      selectAll(val) {
        this.selectedNow = val
      },

      changeCommentsPopup(val){
        this.isModalCommentsPopup = val
      },
      changeSendEmailPopup(val){
        this.isModalSendEmailPopup = val
      },
      changeLastFiveIPPopup(val){
        this.isModalLastFiveIPPopup = val
      },
      changeStatisticPopup(val){
        this.isModalStatisticPopup = val
      },

      changeTab(id) {
        this.activeTab = id
      },
    }
  }
</script>

<style scoped>

</style>


