<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter"
               @changeFilter="changeFilter">
    <template slot="body">
<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['deliveryStatistics_TrackingNumber',])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('deliveryStatistics_TrackingNumber.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="trackNumber"-->
<!--        />-->
<!--      </div>-->

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>
        <SearchSelect
            :options="options"
            :label="$t('shop_user.localization_value.value')"
            :optionsLabel="'email.contacts[0]'"
            :functionSearch="functionSearchUserFilter"
            @change="changeUsersFilter"
            :selected="userName"
            :typeSelect="'users'"
            :userEmail="true"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['deliveryStatistics_Accepted',])"></div>
        <DatePickerDefault
            :label="$t('deliveryStatistics_Accepted.localization_value.value')"
            v-model="acceptedDate"
        >
          <template slot="body">
            <date-picker
                v-model="acceptedDate"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['deliveryStatistics_Delivered',])"></div>-->
<!--        <DatePickerDefault-->
<!--            :label="$t('deliveryStatistics_Delivered.localization_value.value')"-->
<!--            v-model="deliveredDate"-->
<!--        >-->
<!--          <template slot="body">-->
<!--            <date-picker-->
<!--                v-model="deliveredDate"-->
<!--                ref="datePicker"-->
<!--                valueType="format"-->
<!--                range-->
<!--                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"-->
<!--                :format="'MM/DD/YY'"-->
<!--                :placeholder="'mm/dd/yy'"-->
<!--            ></date-picker>-->
<!--          </template>-->
<!--        </DatePickerDefault>-->
<!--      </div>-->


      <div class="table-filter__item"
           v-if="(_.has(navTabs, GLOBAL_DELIVERY_SERVICES.USPS_DELIVERY_SERVICE.name) && !navTabs[GLOBAL_DELIVERY_SERVICES.USPS_DELIVERY_SERVICE.name].active)
           || !navTabs[GLOBAL_DELIVERY_SERVICES.USPS_DELIVERY_SERVICE.name]"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['deliveryStatistics_countryUser'])"></span>
        <DefaultSelect
            :label="$t('deliveryStatistics_countryUser.localization_value.value')"
            :type="'text'"
            :options="(_.has(navTabs, GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name)
            && navTabs[GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name].active) ? optionsCountriesForFedex :
            (_.has(navTabs, GLOBAL_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.name)
            && navTabs[GLOBAL_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.name].active) ? optionsCountriesForDHL : optionsCountries"
            :selected="getUserCountrySelected"
            :optionsLabel="'label'"
            @change="changeCountryUser"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['deliveryStatistics_countryRecipient'])"></span>
        <DefaultSelect
            :label="$t('deliveryStatistics_countryRecipient.localization_value.value')"
            :type="'text'"
            :options="optionsCountries"
            :selected="getRecipientCountrySelected"
            :optionsLabel="'label'"
            @change="changeCountryRecipient"
        />
      </div>

<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit" @click="editTranslate(['deliveryStatistics_countryRecipient'])"></span>-->
<!--        <DefaultSelect-->
<!--            :label="$t('deliveryStatistics_WarehouseCountry.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            :options="selectWarehouseCountryOptions"-->
<!--            :selected="getWarehouseCountrySelected"-->
<!--            :otherValue="'currentTranslation'"-->
<!--            @change="changeWarehouseCountry"-->
<!--        />-->
<!--      </div>-->

      <div class="table-filter__item"
           v-if="_.has(navTabs, GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name) && navTabs[GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name].active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['deliveryStatistics_countryRecipient'])"></span>
        <DefaultSelect
            :label="$t('deliveryStatistics_Warehouse.localization_value.value')"
            :type="'text'"
            :options="selectWarehouseOptions"
            :selected="getWarehouseSelected"
            :otherValue="'currentTranslation'"
            @change="changeWarehouse"
        />
<!--            :optionsLabel="'label'"-->
      </div>



    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')"
                  class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
// import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
import DatePicker from 'vue2-datepicker'
import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
import SearchSelect from "@/components/UI/selectiones/SearchSelect/SearchSelect";
import {usersFunc} from "@/mixins/usersMixins/usersFunc";
import {filterCountriesUSA} from "@/services/filterCountries";
import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
import {GLOBAL_DELIVERY_SERVICES} from "@/staticData/staticVariables";
import {
  UKRAINIAN_COUNTRY_ID,
  LATVIA_COUNTRY_ID,
  UNITED_KINGDOM_ID,
  POLAND_COUNTRY_ID
} from "../../../../../../../staticData/staticVariables";

export default {
  name: "DeliveryStatisticsFilter",
  components: {
    DefaultSelect,
    SearchSelect,
    FilterBlock,
    MainButton,
    DatePickerDefault,
    DatePicker,
    // DefaultInput,
  },

  mixins: [filterFunc, usersFunc],

  props: {
    showFilter: Boolean,
    filterGetParams: Object,
    navTabs: Object,
    selectWarehouseCountryOptions: {
      type: Array,
      default: () => {return []}
    },
    selectWarehouseOptions: {
      type: Array,
      default: () => {return []}
    },
  },

  watch: {
    filterGetParams(newVal) {
      this.userId = newVal.userId ? newVal.userId : ''
      this.userName = newVal.userName ? newVal.userName : ''
      this.trackNumber = newVal.trackNumber ? newVal.trackNumber : ''
      this.acceptedDate = newVal.acceptedDate ? this.generateFilterDate(newVal.acceptedDate, 'MM/DD/YY') : ''
      this.deliveredDate = newVal.deliveredDate ? this.generateFilterDate(newVal.deliveredDate, 'MM/DD/YY') : ''
      this.userCountryId = newVal.userCountryId ? newVal.userCountryId : ''
      this.recipientCountryId = newVal.recipientCountryId ? newVal.recipientCountryId : ''
      this.warehouseCountryId = newVal.warehouseCountryId ? newVal.warehouseCountryId : ''
      this.warehouseId = newVal.warehouseId ? newVal.warehouseId : ''
    },

  },

  computed: {
    getUserCountrySelected(){
      if(this.userCountryId === '') return ''
      return this._.find(this.optionsCountries, {id: parseInt(this.userCountryId)})
    },
    getRecipientCountrySelected(){
      if(this.recipientCountryId === '') return ''
      return this._.find(this.optionsCountries, {id: parseInt(this.recipientCountryId)})
    },
    getWarehouseCountrySelected(){
      if(this.warehouseCountryId === '') return ''
      return this._.find(this.selectWarehouseCountryOptions, {id: parseInt(this.warehouseCountryId)})
    },
    getWarehouseSelected(){
      if(this.warehouseId === '') return ''
      return this._.find(this.selectWarehouseOptions, {id: parseInt(this.warehouseId)})
    },
  },

  data() {
    return {
      userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
      userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
      trackNumber: this.filterGetParams.trackNumber ? this.filterGetParams.trackNumber : '',
      acceptedDate: this.filterGetParams.acceptedDate ? this.generateFilterDate(this.filterGetParams.acceptedDate, 'MM/DD/YY') : '',
      deliveredDate: this.filterGetParams.deliveredDate ? this.generateFilterDate(this.filterGetParams.deliveredDate, 'MM/DD/YY') : '',
      userCountryId: this.filterGetParams.userCountryId ? this.filterGetParams.userCountryId : '',
      recipientCountryId: this.filterGetParams.recipientCountryId ? this.filterGetParams.recipientCountryId : '',
      warehouseCountryId: this.filterGetParams.warehouseCountryId ? this.filterGetParams.warehouseCountryId : '',
      warehouseId: this.filterGetParams.warehouseId ? this.filterGetParams.warehouseId : '',

      filterCounts: [
        'userId',
        'userName',
        'trackNumber',
        'acceptedDate',
        'deliveredDate',
        'userCountryId',
        'recipientCountryId',
        'warehouseCountryId',
        'warehouseId',
      ],

      options: [],
      optionsCountries: [],
      optionsCountriesForFedex: [],
      optionsCountriesForDHL: [],

      GLOBAL_DELIVERY_SERVICES: GLOBAL_DELIVERY_SERVICES,
    }
  },


  mounted() {
    this.$store.dispatch('fetchCountries').then(() => {
      let countries = this._.toArray(this.$store.getters.getCountries)
      filterCountriesUSA(countries)
      this.optionsCountries = countries;
      this.optionsCountriesForFedex = countries.filter(item => {
        return item.id === POLAND_COUNTRY_ID || item.id === UKRAINIAN_COUNTRY_ID || item.id === LATVIA_COUNTRY_ID || item.id === UNITED_KINGDOM_ID
      })
      this.optionsCountriesForDHL = countries.filter(item => {
        return item.id === UKRAINIAN_COUNTRY_ID
      })
      // this.getUserCountrySelected()
    })
  },

  methods: {
    closeFilter() {
      this.$emit('close')
    },

    resetFilter() {
      let data = this.resetFilterParams(this, this.filterCounts)

      this.$emit('changeFilter', data)
    },

    changeFilter() {
      let data = this.changeFilterParams(this, this.filterCounts)
      data.acceptedDate = this.changeDateParams(this, 'acceptedDate')
      data.deliveredDate = this.changeDateParams(this, 'deliveredDate')

      this.$emit('changeFilter', data)
    },

    // getUserCountrySelected() {
    //   if (this.userCountryId === '') return ''
    //   return this._.find(this.optionsCountries, {id: parseInt(this.userCountryId)})
    // },

    changeCountryUser(val) {
      this.userCountryId = val.id
    },
    changeCountryRecipient(val) {
      this.recipientCountryId = val.id
    },
    changeWarehouseCountry(val) {
      this.warehouseCountryId = val.id
    },
    changeWarehouse(val) {
      this.warehouseId = val.id
    },
  },
}
</script>

<style scoped>

</style>
