<template>
  <div class="fragment">


    <div class="content-top-line content-top-line--with-bottom-line "
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['deliveryStatistics_Delivery','deliveryStatistics_days'])"></div>
      <div class="content-tabs content-tabs--separator"
           v-bind:class="{'loading-block': loadingDeliveryStatistics}"
           v-if="Object.keys($store.getters.getUserProfile).length > 0"
      >
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico">
              <template v-if="item.ico">
                <img :src="item.ico" alt="company-ico" :width="item.icoWidth">
              </template>
              <template v-else>
                {{item.label}}
              </template>
            </div>
            <span class="content-tabs__item-small">
              {{$t('deliveryStatistics_Delivery.localization_value.value')}} {{item.days}} {{$t('deliveryStatistics_days.localization_value.value')}}
            </span>
          </div>
        </template>
      </div>

<!--      <div class="content-tabs content-tabs&#45;&#45;separator">-->
<!--        <div class="content-tabs__item " v-bind:class="{active: tab === 1}" @click="changeTab(1)">-->
<!--          <div class="content-tabs__ico">-->
<!--            <img src="/img/company-icons-group/tab-tnt.svg" alt="company-ico" width="55">-->
<!--          </div>-->
<!--          <span class="content-tabs__item-small">Delivery ≈ 7 days</span>-->
<!--        </div>-->
<!--        <div class="content-tabs__item" v-bind:class="{active: tab === 2}" @click="changeTab(2)">-->
<!--          <div class="content-tabs__ico">-->
<!--            <img src="/img/company-icons-group/tab-dhl.svg" alt="company-ico" width="105">-->
<!--          </div>-->
<!--          <span class="content-tabs__item-small">Delivery ≈ 4 days</span>-->
<!--        </div>-->
<!--        <div class="content-tabs__item " v-bind:class="{active: tab === 3}" @click="changeTab(3)">-->
<!--          <div class="content-tabs__ico">-->
<!--            <img src="/img/company-icons-group/tab-fedex.svg" alt="company-ico" width="80">-->
<!--          </div>-->
<!--          <span class="content-tabs__item-small">Delivery ≈ 6 days</span>-->
<!--        </div>-->
<!--        <div class="content-tabs__item " v-bind:class="{active: tab === 4}" @click="changeTab(4)">-->
<!--          <div class="content-tabs__ico">-->
<!--            <img src="/img/company-icons-group/tab-landmark.svg" alt="company-ico" width="80">-->
<!--          </div>-->
<!--          <span class="content-tabs__item-small">Delivery ≈ 6 days</span>-->
<!--        </div>-->
<!--        <div class="content-tabs__item " v-bind:class="{active: tab === 5}" @click="changeTab(5)">-->
<!--          <div class="content-tabs__ico">-->
<!--            <img src="/img/company-icons-group/tab-apc.svg" alt="company-ico" width="80">-->
<!--          </div>-->
<!--          <span class="content-tabs__item-small">Delivery ≈ 17days</span>-->
<!--        </div>-->

<!--      </div>-->

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['unidentifiedP_periodOfDays', 'unidentifiedP_all'])"></span>
        <PeriodOfDaysBlock
            v-bind:class="{'loading-block': loadingDeliveryStatistics}"
            :title="$t('unidentifiedP_periodOfDays.localization_value.value')"
            :list="listPeriod"
            :active="activePeriod"
            @changePeriod="changePeriod"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import PeriodOfDaysBlock from "../../../../../../coreComponents/PeriodOfDaysBlock/PeriodOfDaysBlock";


  export default {
    name: "DeliveryStatisticsHead",
    components: {
      ToggleFilterButton,
      PeriodOfDaysBlock,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
      filterGetParams: Object,
      loadingDeliveryStatistics: {
        type: Boolean,
        default: false
      },
    },

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      },

      filterGetParams(newVal) {
        this.activePeriod = newVal.period ? newVal.period : 3
      }

    },

    created() {

    },

    data() {
      return {

        isShowSearchEngineBlock: false,
        tab: this.activeTab,
        isModalChooseExpressOrderPopup: false,

        listPeriod: [
          // this.$t('unidentifiedP_all.localization_value.value'),
          3,
          7,
          15,
          30,
          60
        ],
        activePeriod: this.filterGetParams.period ? this.filterGetParams.period : 3,
      }
    },



    methods: {
      changePeriod(index, item){
        if(Number.isInteger(item)){
          this.$emit('changePeriod', item)
        } else {
          this.$emit('changePeriod', '')
        }
      },

      changeTab(val) {
        this.$emit('changeTab', val)
      },

    }
  }
</script>

<style scoped>

</style>

