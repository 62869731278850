<template>
  <div class="period-of-days">
    <div class="period-of-days__title">
      {{title}}
    </div>
    <div class="period-of-days__list">
      <div class="period-of-days__item"
          v-for="(item, index) in list"
          :key="index"
      >
        <span class="period-of-days__link"
              :class="{active : item == active}"
              @click="changePeriod(index, item)"
        >
          {{item}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "PeriodOfDaysBlock",

    props: [
      'title',
      'list',
      'active'
    ],

    data(){
      return{

      }
    },

    methods:{
      changePeriod(val, item){
        this.$emit('changePeriod',  val + 1, item)
      }
    },
  }
</script>

<style lang="scss">
  @import "../../../scss/colors";
  @import "../../../scss/mixins/mixins";

  .period-of-days{
    display: flex;
    flex-wrap: wrap;

    &__title{
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $brown;
      margin-right: 15px;
    }

    &__list{
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px;
    }

    &__item{
      padding: 0 5px;
    }

    &__link{
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $borderBrown;
      transition: .15s;
      padding: 0 2px;
      position: relative;
      cursor: pointer;

      &:after{
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% - 2px);
        width: calc(100% - 2px);
        background: $accent;
        height:2px;
        opacity: 0;
        transition: .15s;
      }

      &.active{
        &:after{
          opacity: 1;
        }
      }

      &.active,
      &:hover{
        color: $accent;
      }
    }
  }


</style>