<template>
  <DeliveryStatisticsTableUser
      :navTabs="navTabs"
      :filterGetParams="filterGetParams"
      :countFilterParams="countFilterParams"
      :loadingDeliveryStatistics="loadingDeliveryStatistics"
      :selectWarehouseCountryOptions="selectWarehouseCountryOptions"
      :selectWarehouseOptions="selectWarehouseOptions"
      @filter="filter"
      @changeTab="changeTab"
      @changeFilter="changeFilter"
      @resetFilter="resetFilter"
      @showMore="showMore"
      @reloadTable="filter"
      @changePeriod="changePeriod"
  />
</template>

<script>
import DeliveryStatisticsTableUser from "./DeliveryStatisticsTableBlock/DeliveryStatisticsTableBlock";
import {GLOBAL_DELIVERY_SERVICES} from "@/staticData/staticVariables";
import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";
import {
  CAMBRIDGE_WAREHOUSE, LATVIA_COUNTRY_ID,
  LODZ_WAREHOUSE, POLAND_COUNTRY_ID,
  PYRZOWICE_WAREHOUSE,
  RIGA_WAREHOUSE, UKRAINIAN_COUNTRY_ID, UNITED_KINGDOM_ID
} from "../../../../../staticData/staticVariables";

export default {
  name: "DeliveryStatisticsTable",
  components: {
    DeliveryStatisticsTableUser,
  },

  mixins: [routeFilter],

  data() {
    return {
      forPage: this.$store.getters.getTrackingLogForPage,
      page: 1,
      onePage: 1,

      filterType: '',
      filterUserId: '',
      filterUserName: '',
      filterPeriod: '',
      filterUserCountryId: '',
      filterRecipientCountryId: '',
      filterWarehouseCountryId: '',
      filterWarehouseId: '',
      filterTrackNumber: '',
      filterAcceptedDate: [],
      filterDeliveredDate: [],

      countFilterParams: 0,
      filterGetParams: {},

      createData: null,

      loadingDeliveryStatistics: false,

      selectWarehouseCountryOptions: [],
      selectWarehouseOptions: [],

      tabs: {
        [GLOBAL_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.name]: {
          active: false,
          days: 0,
          name: GLOBAL_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.name,
          label: GLOBAL_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.label,
          id: GLOBAL_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.id,
          ico: GLOBAL_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.ico,
          icoWidth: '105',
          permissionVisible: false,
        },
        // [GLOBAL_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.name]: {
        //   active: false,
        //   days: 0,
        //   name: GLOBAL_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.name,
        //   label: GLOBAL_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.label,
        //   id: GLOBAL_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.id,
        //   ico: GLOBAL_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.ico,
        //   icoWidth: '55',
        //   permissionVisible: false,
        // },
        [GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name]: {
          active: false,
          days: 0,
          name: GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name,
          label: GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.label,
          id: GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.id,
          ico: GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.ico,
          icoWidth: '80',
          permissionVisible: false,
        },
        // [GLOBAL_DELIVERY_SERVICES.LANDMARK_DELIVERY_SERVICE.name]: {
        //   active: false,
        //   days: 0,
        //   name: GLOBAL_DELIVERY_SERVICES.LANDMARK_DELIVERY_SERVICE.name,
        //   label: GLOBAL_DELIVERY_SERVICES.LANDMARK_DELIVERY_SERVICE.label,
        //   id: GLOBAL_DELIVERY_SERVICES.LANDMARK_DELIVERY_SERVICE.id,
        //   ico: GLOBAL_DELIVERY_SERVICES.LANDMARK_DELIVERY_SERVICE.ico,
        //   permissionVisible: false,
        // },
        [GLOBAL_DELIVERY_SERVICES.USPS_DELIVERY_SERVICE.name]: {
          active: false,
          days: 0,
          name: GLOBAL_DELIVERY_SERVICES.USPS_DELIVERY_SERVICE.name,
          label: GLOBAL_DELIVERY_SERVICES.USPS_DELIVERY_SERVICE.label,
          id: GLOBAL_DELIVERY_SERVICES.USPS_DELIVERY_SERVICE.id,
          ico: GLOBAL_DELIVERY_SERVICES.USPS_DELIVERY_SERVICE.ico,
          permissionVisible: false,
        },
        [GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.name]: {
          active: false,
          days: 0,
          name: GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.name,
          label: GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.label,
          id: GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.id,
          ico: GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.ico,
          permissionVisible: false,
        },
      },

      navTabs: {
        [GLOBAL_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.name]: {
          active: false,
          days: 0,
          name: GLOBAL_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.name,
          label: GLOBAL_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.label,
          id: GLOBAL_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.id,
          ico: GLOBAL_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.ico,
          icoWidth: '105',
          permissionVisible: false,
        },
        // [GLOBAL_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.name]: {
        //   active: false,
        //   days: 0,
        //   name: GLOBAL_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.name,
        //   label: GLOBAL_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.label,
        //   id: GLOBAL_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.id,
        //   ico: GLOBAL_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.ico,
        //   icoWidth: '55',
        //   permissionVisible: false,
        // },
        [GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name]: {
          active: false,
          days: 0,
          name: GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name,
          label: GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.label,
          id: GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.id,
          ico: GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.ico,
          icoWidth: '80',
          permissionVisible: false,
        },
        // [GLOBAL_DELIVERY_SERVICES.LANDMARK_DELIVERY_SERVICE.name]: {
        //   active: false,
        //   days: 0,
        //   name: GLOBAL_DELIVERY_SERVICES.LANDMARK_DELIVERY_SERVICE.name,
        //   label: GLOBAL_DELIVERY_SERVICES.LANDMARK_DELIVERY_SERVICE.label,
        //   id: GLOBAL_DELIVERY_SERVICES.LANDMARK_DELIVERY_SERVICE.id,
        //   ico: GLOBAL_DELIVERY_SERVICES.LANDMARK_DELIVERY_SERVICE.ico,
        //   permissionVisible: false,
        // },
        [GLOBAL_DELIVERY_SERVICES.USPS_DELIVERY_SERVICE.name]: {
          active: false,
          days: 0,
          name: GLOBAL_DELIVERY_SERVICES.USPS_DELIVERY_SERVICE.name,
          label: GLOBAL_DELIVERY_SERVICES.USPS_DELIVERY_SERVICE.label,
          id: GLOBAL_DELIVERY_SERVICES.USPS_DELIVERY_SERVICE.id,
          ico: GLOBAL_DELIVERY_SERVICES.USPS_DELIVERY_SERVICE.ico,
          permissionVisible: false,
        },
        [GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.name]: {
          active: false,
          days: 0,
          name: GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.name,
          label: GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.label,
          id: GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.id,
          ico: GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.ico,
          permissionVisible: false,
        },
      }
    }
  },

  mounted() {
    let period = this.$route.query.period ? this.$route.query.period : 3

    this.navTabs = {}
    this.loadingDeliveryStatistics = true

    this.$store.dispatch('getTrackingLogDeliveredStatistics', `?period=${period}`).then(response => {
      let tabs = this.getRespData(response)

      if(tabs.length === 0) {
        this.filter()
        this.loadingDeliveryStatistics = false
        return
      }

      this.navTabs = this._.clone(this.tabs)

      for (let item in this.navTabs) {
        let foundTab = this._.find(tabs, {name: this.navTabs[item]['label']})
        if (foundTab) {
          this.navTabs[item]['days'] = foundTab['avg_days']
        } else {
          delete this.navTabs[item]
        }
      }

      if (this.$route.query.type !== undefined && this.navTabs[this.$route.query.type]) {
        this.skipRadioChecked(this.navTabs, 'active')
        this.changeTab(this.navTabs[this.$route.query.type].name)
      } else {
        this.changeTab(this.navTabs[this._.first(Object.keys(this.navTabs))].name)
      }

      this.loadingDeliveryStatistics = false

    })
  },

  methods: {
    getFilterParam() {
      this.filterGetParams = {
        page: this.$route.query.page,
        count: this.$route.query.count,

        type: this.$route.query.type,
        userId: this.$route.query.userId,
        userName: this.$route.query.userName,
        period: this.$route.query.period,
        userCountryId: this.$route.query.userCountryId,
        recipientCountryId: this.$route.query.recipientCountryId,
        warehouseCountryId: this.$route.query.warehouseCountryId,
        warehouseId: this.$route.query.warehouseId,
        trackNumber: this.$route.query.trackNumber,
        acceptedDate: this.$route.query.acceptedDate,
        deliveredDate: this.$route.query.deliveredDate,
      }
    },

    getDeliveryServices() {
      let oldActive = this._.find(this.navTabs, {active: true})
      let newTabs = this._.clone(this.tabs)
      let period = ''
      if (this.filterPeriod.length > 0 && this.filterPeriod !== this.$t('unidentifiedP_all.localization_value.value')) {
        period = '?period=' + this.filterPeriod
      }

      this.loadingDeliveryStatistics = true

      this.$store.dispatch('getTrackingLogDeliveredStatistics', period).then(response => {
        let tabs = this.getRespData(response)

        if(tabs.length === 0) {
          this.loadingDeliveryStatistics = false
          return
        }

        for (let item in newTabs) {
          let foundTab = this._.find(tabs, {name: newTabs[item]['label']})

          if (foundTab) {
            newTabs[item]['days'] = foundTab['avg_days']
          } else {
            delete newTabs[item]
          }
        }

        let newActive = this._.find(newTabs, {name: oldActive?.name})
        if (newActive){
          newActive.active = true
          this.navTabs = newTabs
          this.filter()
        } else {
          // newTabs[this._.first(Object.keys(newTabs))].active = true
          this.navTabs = newTabs
          this.changeTab(newTabs[this._.first(Object.keys(newTabs))].name)
        }

        this.loadingDeliveryStatistics = false

      }).catch(error => this.createErrorLog(error))
    },

    changePeriod(val) {
      this.changeFilterLocal({period: val})
      this.$store.commit('setTrackingLogLoadingStart')
      this.filterPeriod = '' + val
      this.getDeliveryServices()
    },

    changeFilterLocal(data) {
      data.page = this.onePage
      data.count = this.forPage

      this.getFilterParam()
      this.changeRouteParams(this.mergeRouteParams(), data)
    },

    changeTab(tab, tabs = false) {
      let currentTabs = this.navTabs
      if(tabs){
        currentTabs = this[tabs]
      }
      this.skipRadioChecked(currentTabs, 'active')
      currentTabs[tab].active = true

      this.changeFilter({type: tab}, true)
    },

    changeFilter(data, changedTab = false) {
      data.page = this.onePage
      data.count = this.forPage

      this.getFilterParam()
      this.changeRouteParams(this.mergeRouteParams(), data)

      let getParamsChanged = false

      //ukrainian
      if (!this.isAdmin && this.isUkrainian && !this.$route.query.warehouseId && this.$route.query?.type === GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name) {
        this.$route.query.warehouseId = PYRZOWICE_WAREHOUSE.id
        getParamsChanged = true
      }
      if (!this.isAdmin && this.isUkrainian && !this.$route.query.userCountryId && this.$route.query?.type === GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name) {
        this.$route.query.userCountryId = UKRAINIAN_COUNTRY_ID
        getParamsChanged = true
      }

      //poland
      if (!this.isAdmin && this.isPoland && !this.$route.query.warehouseId && this.$route.query?.type === GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name) {
        this.$route.query.warehouseId = LODZ_WAREHOUSE.id
        getParamsChanged = true
      }
      if (!this.isAdmin && this.isPoland && !this.$route.query.userCountryId && this.$route.query?.type === GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name) {
        this.$route.query.userCountryId = POLAND_COUNTRY_ID
        getParamsChanged = true
      }

      //latvian
      if (!this.isAdmin && this.isLatvian && !this.$route.query.warehouseId && this.$route.query?.type === GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name) {
        this.$route.query.warehouseId = RIGA_WAREHOUSE.id
        getParamsChanged = true
      }
      if (!this.isAdmin && this.isLatvian && !this.$route.query.userCountryId && this.$route.query?.type === GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name) {
        this.$route.query.userCountryId = LATVIA_COUNTRY_ID
        getParamsChanged = true
      }

      //UK
      if (!this.isAdmin && this.isUnitedKingdomCountryByUserData(this.$store.getters.getUserProfile?.user_personal_contact?.country_id)
          && !this.$route.query.warehouseId && this.$route.query?.type === GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name) {
        this.$route.query.warehouseId = CAMBRIDGE_WAREHOUSE.id
        getParamsChanged = true
      }
      if (!this.isAdmin && this.isUnitedKingdomCountryByUserData(this.$store.getters.getUserProfile?.user_personal_contact?.country_id)
          && !this.$route.query.userCountryId && this.$route.query?.type === GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name) {
        this.$route.query.userCountryId = UNITED_KINGDOM_ID
        getParamsChanged = true
      }

      if (changedTab) {
        if (!this.isAdmin && this.$route.query?.type !== GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name) {
          this.$route.query.userCountryId = ''
          this.$route.query.warehouseId = ''
          getParamsChanged = true
        }
      }

      if (getParamsChanged) {
        this.getFilterParam()
      }

      this.filter()
    },

    getDataForSelectFilters() {
      if(this.$route.query.type && this.navTabs[this.$route.query.type]?.id) {
        this.$store.dispatch('fetchWarehousesFromDeliveryStatistics', '?delivery_service_id=' + this.navTabs[this.$route.query.type].id).then(response => {
          let data = this.getRespData(response)

          if(data?.countries) {
            this.selectWarehouseCountryOptions = data.countries
          }

          if(data?.warehouses) {
            this.selectWarehouseOptions = data.warehouses
          }
        })
      }
    },

    filter(next = false) {

      this.getFilterParam()
      let forPage, page, url

      this.getDataForSelectFilters()

      // generate main filter if has get params in URL
      let generateMainFilter = this.generateMainFilterFromUrl(next, this)

      page = generateMainFilter.page
      forPage = generateMainFilter.forPage

      // generate other filter if has get params in URL
      this.generateParamsFilterFromUrl(this)


      // Generate filter URL
      url = this.generateFilterUrl(page, forPage)
      console.log(url);

      // True if get next page
      if (next) this.$store.commit('setNextTrackingLogPage', true)

      // save filter params in store
      this.$store.commit('setTrackingLogFilter', window.location.search)

      this.$store.dispatch('fetchTrackingLog', url).then(() => {
        // skip get next page
        this.$store.commit('setNextTrackingLogPage', false)
      })

      this.checkCountFilter(['type', 'userName', 'period'])
    },

    generateFilterUrl(page, forPage) {
      let myQuery = this.createQueryBuilderObject({withUserNewMark: false})

      let qParams = {
        filterTrackNumber: 'tracking_number',
        filterUserId: 'userId',
        filterUserCountryId: 'userCountryId',
        filterRecipientCountryId: 'recipientCountryId',
        filterWarehouseCountryId: 'senderWarehouseCountryId',
      }

      //prevent this param from messing with other delivery service tabs
      if (this._.has(this.navTabs, GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name) && this.navTabs[GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name].active) {
        qParams['filterWarehouseId'] = 'senderWarehouseId'
      }

      this.generateFilterQueryParams(
          myQuery, qParams,
      )

      if (this.filterAcceptedDate.length > 0) {
        let deliveryDate = this.generateDateForFilter(this.filterAcceptedDate, 'YYYY-MM-DD')
        myQuery.whereIn('acceptedAt', deliveryDate)
      }

      if (this.filterDeliveredDate.length > 0) {
        let deliveryDate = this.generateDateForFilter(this.filterDeliveredDate, 'YYYY-MM-DD')
        myQuery.whereIn('deliveredAt', deliveryDate)
      }

      if(this.navTabs[this.filterType]?.id){
        myQuery.where('delivery_service_id', this.navTabs[this.filterType].id)
        myQuery.params({ delivery_service_id: this.navTabs[this.filterType].id })
      }
      myQuery.where('delivery_status', 'delivered')

      let params = this.generateGetParamsFromQuery(myQuery, forPage, page)

      if (this.filterPeriod.length > 0) {
        params += '&period=' + this.filterPeriod
      } else {
        params += '&period=' + 3
      }

      return params
    },

    removeTrackingData() {
      this.$store.commit('setTrackingLog', {trackingLog: []})
      this.$store.commit('setTrackingLogCommonList', {trackingLogCommonList: []})
    },

  },

  destroyed() {
    this.removeTrackingData()
  }

}
</script>

<style scoped>

</style>